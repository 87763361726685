import { ButtonProps as $ButtonProps, ButtonType } from 'antd5/lib/button';
import React from 'react';

import { Button as $Button } from '../components/common/antd/antd-override';

export type OmitPropKeys = 'ghost' | 'shape' | 'size' | 'type';
export type ButtonProps = Omit<$ButtonProps, OmitPropKeys> & {
  type?: ButtonType | string;
};

export default function Button({ type = 'primary', className, ...rest }: ButtonProps) {
  if (!['default', 'link', 'dashed', 'danger'].includes(type)) {
    return <$Button variant="solid" className={`ant-btn-${type} ${className}`} {...rest} />;
  }
  return <$Button variant="solid" type={type as ButtonType} className={className} {...rest} />;
}
