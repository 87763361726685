import React, { useCallback } from 'react';
import { Alert as $Alert } from 'antd';
import { AlertProps as $AlertProps } from 'antd/lib/alert';
import CheckCircle from '@material-ui/icons/CheckCircle';
import Warning from '@material-ui/icons/Warning';

import { AlertCloseClickCallback } from '.';

export type AlertProps = Omit<$AlertProps, 'onClose'> & { onClose?: AlertCloseClickCallback };

export default function Alert({ type = 'error', showIcon = true, closable = true, onClose, ...rest }: AlertProps) {
  const onCloseAlert = useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.preventDefault();
      onClose && onClose({ alertType: type });
    },
    [onClose, type]
  );

  return (
    <$Alert
      showIcon={showIcon}
      closable={closable}
      type={type}
      onClose={onCloseAlert}
      icon={type === 'error' ? <Warning /> : <CheckCircle />}
      {...rest}
    ></$Alert>
  );
}
